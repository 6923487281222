.root {
  @apply py-4;
  @apply px-6;
  @apply border;
  @apply w-full;
  @apply bg-white;
  @apply shadow-md;
  @apply rounded-xl;
  @apply text-primary;
  @apply font-semibold;
  @apply border-primary;
  @apply shadow-common/10;
  @apply data-[is-completed=true]:opacity-30;
  @apply data-[is-completed=true]:cursor-default;
  @apply data-[is-completed=true]:pointer-events-none;
}
