.root {
  @apply w-10;
  @apply h-10;
  @apply -top-2;
  @apply right-0;
  @apply absolute;
  @apply translate-x-1/2;

  @apply data-[ready=false]:hidden;
  @apply data-[ready=false]:pointer-events-none;
}

.root .icon {
  @apply w-6;
  @apply h-6;
  @apply top-1/2;
  @apply left-1/2;
  @apply absolute;
  @apply bg-primary;
  @apply transition-all;
  @apply -translate-x-1/2;
  @apply -translate-y-1/2;
  @apply [mask-size:contain];
  @apply [mask-position:center];
  @apply [mask-repeat:no-repeat];

  @apply data-[state='idle']:[mask-image:url('assets/icons/volume.svg')];
  @apply data-[state='playing']:[mask-image:url('assets/icons/pause.svg')];
  @apply data-[state='paused']:[mask-image:url('assets/icons/play.svg')];

  @apply before:w-0;
  @apply before:h-0;
  @apply before:-z-10;
  @apply before:absolute;
  @apply before:opacity-0;
  @apply before:content-[url('assets/icons/pause.svg')_url('assets/icons/play.svg')];
}
