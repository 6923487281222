@import '@sbthuman/survey-front/assets/styles/index.css';

body {
  --colors-primary-500-rgb: 58, 0, 202;
  --colors-accent-500-rgb: 251, 58, 114;
  --colors-base-500-rgb: 24, 3, 77;
  --colors-base-700-rgb: 24, 3, 77;
  --colors-base-900-rgb: 24, 3, 77;

  @apply font-sans;
}

.hmq-modal-confirm--overlay {
  @apply bg-gray-50/90;
}

.hmq-modal-confirm--card--title {
  @apply text-primary;
}

.hmq-modal-confirm--card--buttons {
  @apply justify-start;
}

.hmq-modal-confirm--card--buttons .hmq-button {
  @apply focus-visible:ring-0;
}

.hmq-layout {
  @apply grid;
  @apply min-h-full;
  @apply shadow-none;
}

.hmq-intro--content {
  @apply p-0;
}

.hmq-intro--content--title {
  @apply text-primary;
}

.hmq-intro--content--text span {
  @apply mb-6;
  @apply block;
  @apply text-xs;
  @apply text-gray-500;
}

.hmq-intro--content--text ul {
  @apply flex;
  @apply gap-3;
  @apply flex-col;
}

.hmq-intro--content--text li {
  @apply text-base;
  @apply text-common;
  @apply list-inside;
  @apply list-decimal;
}

.hmq-navigation {
  @apply px-0;
}

.hmq-intro--navigation {
  @apply pb-6;
  @apply px-0;
  @apply bg-white/70;
  @apply backdrop-blur-sm;
}

.hmq-intro--navigation .hmq-button {
  @apply h-10;
  @apply pr-10;
  @apply text-sm;
  @apply normal-case;
  @apply rounded-full;
  @apply tracking-normal;
  @apply after:w-4;
  @apply after:h-4;
  @apply after:right-4;
  @apply after:absolute;
  @apply after:bg-center;
  @apply after:content-[''];
  @apply after:bg-no-repeat;
  @apply after:bg-[url('../icons/chevronRightWhite.svg')];
}

.hmq-navigation {
  @apply pb-6;
  @apply border-none;
  @apply bg-white/70;
  @apply justify-between;
  @apply backdrop-blur-sm;
}

.hmq-intro--navigation {
  @apply border-none;
}

.hmq-button[data-role="next"] {
  @apply h-10;
  @apply pr-10;
  @apply text-sm;
  @apply normal-case;
  @apply rounded-full;
  @apply tracking-normal;
  /* DISABLED */
  @apply disabled:text-white;
  @apply disabled:opacity-20;
  /* AFTER */
  @apply after:w-4;
  @apply after:h-4;
  @apply after:right-4;
  @apply after:absolute;
  @apply after:bg-center;
  @apply after:content-[''];
  @apply after:bg-no-repeat;
  @apply after:bg-[url('../icons/chevronRightWhite.svg')];
}

.hmq-button[data-role="back"] {
  @apply h-10;
  @apply pl-6;
  @apply pr-0;
  @apply text-sm;
  @apply normal-case;
  @apply rounded-full;
  @apply text-primary;
  @apply bg-transparent;
  @apply tracking-normal;
  /* AFTER */
  @apply after:w-4;
  @apply after:h-4;
  @apply after:left-0;
  @apply after:absolute;
  @apply after:bg-center;
  @apply after:content-[''];
  @apply after:bg-no-repeat;
  @apply after:bg-[url('../icons/chevronLeftPrimary.svg')];
}

.hmq-modality-scale-labels--options--option--label {
  @apply text-gray-500;
  @apply text-left;
  @apply sm:text-center;
}

.hmq-modality-scale-labels--options--option-selected .hmq-modality-scale-labels--options--option--label {
  @apply text-primary;
}

.hmq-questions--slide[data-index="0"] .hmq-navigation {
  @apply justify-end;
}

.hmq-questions--slide[data-index="0"] .hmq-button[data-role="back"] {
  @apply hidden;
}

.hmq-progress {
  @apply bg-common/10;
  @apply rounded-full;
  @apply overflow-hidden;
}

.hmg-speech {
  @apply relative;
  @apply translate-x-0;
  @apply translate-y-0;
  @apply rounded-full;
  @apply shadow-md;

  @apply md:absolute;
  @apply md:-left-12;
  @apply md:-top-1;
}

.hmq-group-default--required-legend {
  @apply px-0;
}

.hmq-question-single-modality {
  @apply p-0;
}
