.root {
  @apply h-2;
  @apply grid;
  @apply my-4;
  @apply w-full;
  @apply gap-0.5;
  @apply rounded-full;
  @apply auto-cols-fr;
  @apply grid-flow-col;
  @apply overflow-hidden;
}

.root .cell {
  @apply h-full;
  @apply bg-gray-100;
  @apply data-[filled=true]:bg-accent;
}
