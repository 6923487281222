.root {}

.root .head .back {
  @apply left-2;
  @apply absolute;
}

.root .container {
  @apply w-full;
  @apply mx-auto;
  @apply max-w-md;
}

.root .container .title {
  @apply py-4;
  @apply text-xl;
  @apply text-center;
  @apply text-common;
}

.root .container .title .primary {
  @apply text-primary;
  @apply font-semibold;
}

.root .container .items {
  @apply mb-6;
  @apply grid;
  @apply w-full;
}

.root .container .items .item {
  @apply py-3;
  @apply flex;
  @apply relative;
  @apply items-center;
  @apply before:w-0.5;
  @apply before:h-full;
  @apply before:top-0;
  @apply before:left-3;
  @apply before:absolute;
  @apply before:bg-primary;
  @apply before:content-[''];
  @apply before:-translate-x-1/2;
  @apply first:before:h-1/2;
  @apply first:before:top-1/2;
  @apply last:before:h-1/2;
  @apply last:before:bottom-1/2;
}

.root .container .items .item .bullet {
  @apply w-6;
  @apply h-6;
  @apply mr-3;
  @apply flex;
  @apply z-10;
  @apply relative;
  @apply shrink-0;
  @apply bg-primary;
  @apply rounded-full;
  @apply items-center;
  @apply justify-center;
}

.root .container .items .item .bullet .icon {
  @apply w-4;
  @apply h-4;
  @apply text-white;
}
