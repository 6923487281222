.root {
  @apply px-4;
  @apply h-12;
  @apply w-full;
  @apply outline-0;
  @apply outline-none;
  @apply bg-transparent;
}

.root:-webkit-autofill {
  transition: background-color 9999s ease-in-out 0s;
}
.root:-webkit-autofill:hover {
  transition: background-color 9999s ease-in-out 0s;
}
.root:-webkit-autofill:focus {
  transition: background-color 9999s ease-in-out 0s;
}
.root:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s;
}
.root:-webkit-autofill:first-line {
  transition: background-color 9999s ease-in-out 0s;
}

.root::-webkit-outer-spin-button,
.root::-webkit-inner-spin-button {
  @apply appearance-none;
  @apply m-0;
}

.root[type=number] {
  -moz-appearance: textfield;
}
