.root {
  @apply flex;
  @apply w-fit;
  @apply gap-2;
  @apply text-primary;
  @apply rounded-full;
  @apply items-center;
  @apply font-semibold;
  @apply flex-shrink-0;
  @apply justify-center;
  @apply disabled:opacity-20;
  @apply disabled:pointer-events-none;
  /* SIZE SM */
  @apply data-[size='sm']:h-8;
  @apply data-[size='sm']:px-4;
  @apply data-[size='sm']:text-xs;
  /* SIZE MD */
  @apply data-[size='md']:h-10;
  @apply data-[size='md']:px-6;
  @apply data-[size='md']:text-sm;
  /* FILLED */
  @apply data-[variant='filled']:text-white;
  @apply data-[variant='filled']:bg-primary;
  /* OUTLINED */
  @apply data-[variant='outlined']:border-primary;
  @apply data-[variant='outlined']:border;
}
