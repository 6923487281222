.root {
  @apply mt-px;
  @apply left-4;
  @apply top-2.5;
  @apply absolute;
  @apply text-gray-400;
  @apply transition-all;
  @apply pointer-events-none;

  @apply data-[is-invalid=true]:text-red-500;
}

.root + legend {
  @apply px-2;
  @apply ml-2;
  @apply hidden;
  @apply text-xs;
  @apply opacity-0;
  @apply leading-0;
}
