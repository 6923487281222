.root {
  @apply px-4;
  @apply py-3;
  @apply w-full;
  @apply outline-0;
  @apply resize-none;
  @apply min-h-[3rem];
  @apply outline-none;
  @apply bg-transparent;
}
