.root {
  @apply h-12;
  @apply pl-4;
  @apply pr-12;
  @apply w-full;
  @apply outline-0;
  @apply outline-none;
  @apply bg-transparent;
  @apply cursor-pointer;
  @apply appearance-none;
}

.chevron {
  @apply z-10;
  @apply right-4;
  @apply absolute;
}
