.root {
  @apply flex;
  @apply h-full;
  @apply flex-col;
}

.root .progress {
  @apply mx-6;
  @apply pt-6;
}

.root .head .back {
  @apply left-2;
  @apply absolute;
}

.root .progressContainer {
  @apply py-4;
}

.root .surveyContainer {
  @apply grid;
  @apply w-full;
  @apply flex-grow;
}


