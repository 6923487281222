.input {
  @apply w-0;
  @apply h-0;
  @apply absolute;
}

.root {
  @apply w-5;
  @apply h-5;
  @apply flex;
  @apply rounded;
  @apply outline-2;
  @apply bg-gray-200;
  @apply items-center;
  @apply justify-center;
  @apply transition-colors;
  @apply outline-transparent;
}

.input:focus-visible + .root {
  @apply ring-2;
  @apply ring-primary;
  @apply ring-offset-2;
}

.root .mark {
  @apply w-4;
  @apply h-4;
  @apply opacity-0;
  @apply text-white;
  @apply transition-opacity;
}

.input:checked + .root {
  @apply bg-primary;
}

.input:checked + .root .mark {
  @apply opacity-100;
}
