.root {
  @apply flex;
  @apply w-full;
  @apply static;
  @apply flex-col;
  @apply my-auto;
  @apply shadow-none;
}

.root .head .back {
  @apply left-2;
  @apply absolute;
}

.root .container {
  @apply mb-10;
  @apply md:px-16;
}

.root .container .title {
  @apply mb-4;
  @apply w-full;
  @apply text-lg;
  @apply text-common;
}

.root .container .required {
  @apply mb-8;
  @apply text-xs;
  @apply text-gray-500;
}

.root .container .form {
  @apply grid;
  @apply w-full;
  @apply gap-y-6;
  @apply gap-x-10;
}

.root .container .form .checkboxes {
  @apply mb-2;
  @apply grid;
  @apply gap-3;
  @apply grid-cols-3;
}

.root .container .form .button {
  @apply mt-6;
  @apply mx-auto;
  @apply col-span-full;
}
