.root {
  @apply w-12;
  @apply h-12;
  @apply flex;
  @apply shrink-0;
  @apply relative;
  @apply items-center;
  @apply rounded-full;
  @apply justify-center;
  @apply before:w-full;
  @apply before:h-full;
  @apply before:scale-0;
  @apply before:absolute;
  @apply before:bg-common/10;
  @apply before:content-[''];
  @apply before:rounded-full;
  @apply before:transition-transform;
  @apply hover:before:scale-100;
}

.root > * {
  @apply w-6;
  @apply h-6;
  @apply z-10;
  @apply relative;
  @apply text-primary;
}
