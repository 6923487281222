.root {
  @apply flex;
  @apply h-fit;
  @apply w-full;
  @apply border;
  @apply relative;
  @apply rounded-md;
  @apply min-h-[3rem];
  @apply items-center;
  @apply border-gray-200;
  @apply transition-colors;
}

/* WITH FOCUS */

.root:focus-within {
  @apply text-inherit;
  @apply border-primary;
}

.root:focus-within .label {
  @apply text-xs;
  @apply -top-2.5;
  @apply text-primary;
}

.root:focus-within legend {
  @apply block;
}

/* WITH VALUE */

.root > [data-empty=false] ~ .label {
  @apply text-xs;
  @apply -top-2.5;
}

.root > [data-empty=false] ~  legend {
  @apply block;
}

/* INVALID */

.root[data-is-invalid=true] {
  @apply border-red-500;
}

.root[data-is-invalid=true] .label {
  @apply text-red-500;
}

